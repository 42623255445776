<template>
    <div class="error-not-found">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="error-inner text-center">
                        <div class="image">
                            <img src="/assets/img/icons/404.png" alt="404">
                        </div>
                        <h1 class="heading-h3 text-white">Parece que estás perdido.</h1>
                        <div class="error-text">
                            <p class="text-white">Parece que no se encontró nada en esta ubicación. Puede volver a la última página o ir a la página de inicio.</p>
                            <div class="error-button-group">
                                <button 
                                  type="button"    
                                  @click="$router.go(-1)" class="btn ht-btn--round">Regresar
                                </button>
                                <router-link class="btn ht-btn--round" to="/" style="margin-left: 10px;">
                                    Ir a la página principal
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>

<style type="scss" scoped>
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
}
</style>